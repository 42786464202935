import { xcss } from '@atlaskit/primitives';
import { type NextBestAction as NextBestActionType } from '@post-office/confluence-next-best-action';
import { type ClickedElementAttributes } from '@post-office/shared-contracts';
import { type FunctionComponent, type ReactNode, useMemo } from 'react';

import { NextBestActionPressable } from '../next-best-action-button';
import { useActionMessageClicked } from '../next-best-action-button/use-action-message-clicked';

const nextBestActionButtonStyles = xcss({
	display: 'flex',
	width: '120px',
	paddingLeft: '0',
	paddingRight: '0',
	whiteSpace: 'nowrap',
	color: 'color.link',
	backgroundColor: 'color.background.accent.blue.subtlest',
	':hover': {
		textDecoration: 'underline',
	},
});

export const NextBestAction: FunctionComponent<{
	ctaMessage: ReactNode;
	onCTAClick: (e: React.MouseEvent<HTMLElement>) => void;
	nextBestActionType: NextBestActionType;
}> = ({ ctaMessage, onCTAClick, nextBestActionType }) => {
	const clickedElement = useMemo<ClickedElementAttributes>(() => {
		return {
			clickIdentifier: `next-best-action-message-${nextBestActionType}`,
			clickedElement: 'link',
		};
	}, [nextBestActionType]);

	const { handleClick } = useActionMessageClicked(onCTAClick, clickedElement);

	return (
		<NextBestActionPressable
			ctaText={ctaMessage}
			onCTAClick={handleClick}
			pressableStyle={nextBestActionButtonStyles}
		/>
	);
};
