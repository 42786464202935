import { B100 } from '@atlaskit/theme/colors';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';
import { css } from '@compiled/react';
import { type AdControls } from '@post-office/components--ad-controls';
import { type NextBestAction as NextBestActionType } from '@post-office/confluence-next-best-action';
import { useMessageContext } from '@post-office/message-context';
import { type ClickedElementAttributes } from '@post-office/shared-contracts';
import { type ComponentProps, type FunctionComponent, type ReactNode, useMemo } from 'react';
import { FormattedMessage } from 'react-intl-next';

import { Actions } from './components/actions';
import { NextBestAction } from './components/cta';
import { useActionMessageClicked } from './components/next-best-action-button/use-action-message-clicked';
import { type Nba } from './types';

export type Props = {
	onMessageClick: () => void;
	whyAmISeeingThisModalContent?: ComponentProps<typeof AdControls>['whyAmISeeingThisModalContent'];
	handleOnDismissed: () => void;
	nextBestActionType: NextBestActionType;
} & Nba;

/*--Banner Content--*/

const confluenceNbaMessageStyles = css({
	display: 'flex',
	position: 'relative',
	alignItems: 'center',
	borderRadius: token('border.radius.200', '8px'),
	backgroundColor: token('color.background.accent.blue.subtlest', B100),
	width: '100%',
	padding: `${token('space.150', '12px')} ${token('space.150', '12px')} ${token('space.150', '12px')}${token('space.075', '6px')}`,
	'&:hover': {
		cursor: 'pointer',
		outline: `1px solid ${token('color.background.accent.blue.subtle.hovered')}`,
	},
});

const iconContainerStyles = css({
	display: 'flex',
	position: 'absolute',
	top: '12px',
	width: '40px',
	height: '40px',
});

const bodyContentStyles = css({
	display: 'flex',
	flexDirection: 'column',
	marginLeft: '40px',
	paddingLeft: token('space.100', '8px'),
	marginRight: '32px',
	width: '100%',
});

const headerStyles = css({
	width: '100%',
	font: token('font.heading.xsmall', fontFallback.heading.xsmall),
	paddingBottom: token('space.100', '8px'),
});

const BodyContent: FunctionComponent<{
	header: ReactNode;
	ctaMessage: ReactNode;
	onMessageClick: (e: React.MouseEvent<HTMLElement>) => void;
	nextBestActionType: NextBestActionType;
}> = ({ header, ctaMessage, onMessageClick, nextBestActionType }) => {
	return (
		<div css={bodyContentStyles}>
			<div role="presentation" css={headerStyles}>
				{header}
			</div>
			<NextBestAction
				ctaMessage={ctaMessage}
				onCTAClick={onMessageClick}
				nextBestActionType={nextBestActionType}
			/>
		</div>
	);
};

/*--Reasons List--*/

const REASONS = [
	<FormattedMessage
		id="post-office.ad-controls.next-best-action-reason.actions-done-so-far"
		description="the first reason for displaying the message to the customer: what actions the customer has actioned so far in the product"
		defaultMessage="What you’ve done so far in the product"
	/>,
	<FormattedMessage
		id="post-office.ad-controls.next-best-action-reason.suggestions"
		description="the second reason for displaying the message to the customer: what we suggest to help the customer"
		defaultMessage="What we suggest to help you get the most value from Atlassian"
	/>,
];

type ReasonsListProps = { reasons: JSX.Element[] };

const reasonsListStyles = css({
	listStyleType: 'disc',
	paddingLeft: token('space.250', '20px'),
	marginTop: token('space.150', '12px'),
});

const ReasonsList = ({ reasons }: ReasonsListProps) => (
	<ul css={reasonsListStyles}>
		{reasons.map((reason, index) => (
			<li key={index}>{reason}</li>
		))}
	</ul>
);

const whyAmISeeingThisModalContent = {
	title: (
		<FormattedMessage
			id="post-office.ad-controls.next-best-action-title.why-am-i-seeing-this"
			description="the title to the modal"
			defaultMessage="Why am I seeing this?"
		/>
	),
	message: (
		<p>
			<FormattedMessage
				id="post-office.ad-controls.next-best-action-message.recommendation-basis"
				description="the main message of the modal"
				defaultMessage="This recommendation is based on:"
			/>
		</p>
	),
	reasons: <ReasonsList reasons={REASONS} />,
};

export const ConfluenceNbaBannerMessage = ({
	icon,
	header,
	ctaMessage,
	onMessageClick,
	handleOnDismissed,
	nextBestActionType,
}: Props) => {
	const { messageInstanceId } = useMessageContext();
	if (!messageInstanceId) {
		throw new Error('messageInstanceId is required');
	}

	const clickedElement = useMemo<ClickedElementAttributes>(() => {
		return {
			clickIdentifier: `next-best-action-message-${nextBestActionType}`,
			clickedElement: 'link',
		};
	}, [nextBestActionType]);

	const { handleClick } = useActionMessageClicked(onMessageClick, clickedElement);

	const handleMessageClick = (e: React.MouseEvent<HTMLElement>) => {
		const currentTarget = e.currentTarget as HTMLElement;

		let target = e.target as HTMLElement;
		while (target.parentNode) {
			if (
				target.tagName.toLowerCase() === 'button' ||
				target.tagName.toLowerCase() === 'a' ||
				target.tagName.toLowerCase() === 'html' // this is for the modal & its transparent background
			) {
				// Don't open action since these child elements should've already handled the click
				return;
			}

			// Open action if the click is on the banner message itself
			if (target === currentTarget) {
				handleClick(e);
				return;
			}

			// Keep traversing up the DOM tree
			target = target.parentNode as HTMLElement;
		}
	};

	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
		<div
			data-testid="confluence-next-best-action-message"
			css={confluenceNbaMessageStyles}
			onClick={handleMessageClick}
		>
			<div css={iconContainerStyles}>{icon}</div>
			<BodyContent
				header={header}
				ctaMessage={ctaMessage}
				onMessageClick={onMessageClick}
				nextBestActionType={nextBestActionType}
			/>
			<Actions
				whyAmISeeingThisModalContent={whyAmISeeingThisModalContent}
				handleOnDismissed={handleOnDismissed}
			/>
		</div>
	);
};
