import { token } from '@atlaskit/tokens';
import { css } from '@compiled/react';
import type { NextBestAction } from '@post-office/confluence-next-best-action';
import {
	type BackendMessageBody,
	type DefaultMessagesResponse,
	createPlacement,
} from '@post-office/placement-common-v2';
import type { SerializableRecord } from '@post-office/serializable-record';
import { usePlacementApi } from '@post-office/use-placement-api';
import { useCallback, useEffect, useState } from 'react';

const { createFrontend, createBackend } = createPlacement({
	placementId: 'confluence-side-nav',
	options: {
		choreographer: {
			isDefaultEnabled: false,
		},
	},
});

export const PlacementBackend = createBackend({
	params: {
		dataRetrivalType: 'implicit-only',
	},
});

// Props passed from Product to Placement
type PlacementFrontendProps = {
	preloadedInitialData: DefaultMessagesResponse | undefined;
	onDismiss: (messages: { messages: BackendMessageBody<SerializableRecord>[] }) => void;
	onSideNavClick: (
		nextBestActionType: NextBestAction,
		preloadedMessageState: { messages: BackendMessageBody<SerializableRecord>[] },
	) => void;
};

const confluenceSideNavStyles = css({
	display: 'flex',
	margin: token('space.150', '12px'),
});

const SORTED_NBA: NextBestAction[] = ['CREATE_SPACE', 'CREATE_PAGE', 'CREATE_WHITEBOARD'];

// todo: Resolve backend ordering incorrectly
const sortMessages = (messages: BackendMessageBody<SerializableRecord>[] = []) => {
	return messages.sort((a, b) => {
		return (
			// @ts-expect-error Property 'nextBestActionType' does not exist on type 'BackendMessageBody<SerializableRecord>'
			SORTED_NBA.indexOf(a?.nextBestActionType as NextBestAction) -
			// @ts-expect-error Property 'nextBestActionType' does not exist on type 'BackendMessageBody<SerializableRecord>'
			SORTED_NBA.indexOf(b?.nextBestActionType as NextBestAction)
		);
	});
};

export const PlacementFrontend = createFrontend(
	(Messages) =>
		({ preloadedInitialData, onDismiss, onSideNavClick }: PlacementFrontendProps) => {
			const [{ messages, status }, setMessageState] = useState<{
				messages: BackendMessageBody<SerializableRecord>[] | undefined;
				status: 'loading' | 'done';
			}>(() => {
				if (preloadedInitialData) {
					return preloadedInitialData?.messages?.length > 0
						? {
								status: 'done',
								messages: sortMessages(preloadedInitialData?.messages),
							}
						: { messages: undefined, status: 'done' };
				}

				return { messages: undefined, status: 'loading' };
			});

			const isDone = status === 'done';

			const { data, error, isLoading } = usePlacementApi<DefaultMessagesResponse>('/', {
				autoRefetch: {
					isEnabled: !isDone,
					updateKey: 'confluence-side-nav',
				},
			});

			const handleOnDismissed = useCallback(() => {
				// no more messages until the next fetch
				onDismiss({ messages: [] });

				setMessageState({ messages: undefined, status: 'done' });
			}, [onDismiss]);

			const handleOnSideNavClick = useCallback(
				(nextBestActionType: NextBestAction) => {
					// display the next message
					setMessageState((prevState) => {
						if (prevState.messages) {
							const newMessages = prevState.messages.slice(1);

							onSideNavClick(nextBestActionType, { messages: newMessages });

							return {
								messages: newMessages,
								status: 'done',
							};
						}

						onSideNavClick(nextBestActionType, { messages: [] });

						return prevState;
					});
				},
				[onSideNavClick],
			);

			useEffect(() => {
				if (!isLoading && !isDone) {
					setMessageState({
						messages: sortMessages(data?.messages),
						status: 'done',
					});
				}
			}, [isLoading, isDone, data]);

			if (error) {
				return null;
			}

			if (status === 'loading') {
				return null;
			}

			return Array.isArray(messages) && messages.length > 0 ? (
				<div css={confluenceSideNavStyles}>
					<Messages.render
						{...messages[0]}
						onSideNavClick={handleOnSideNavClick}
						handleOnDismissed={handleOnDismissed}
					/>
				</div>
			) : null;
		},
);
